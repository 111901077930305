<template>
  <v-container class="container-card">
    <FiltroRelatorios @selectedFilters="filters = $event" />
    <Relatorios :filters="filters" :list="menus" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import FiltroRelatorios from "@/components/comissao/relatorios/FiltroRelatorios";
import Relatorios from "@/components/comissao/relatorios/Relatorios";
export default {
  components: {
    FiltroRelatorios,
    Relatorios
  },
  data() {
    return {
      filters: {},
      menus: [
        {
          title: "Relatórios",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Realizado Empresa",
              service: "realizadoEmpresa",
              permission: 1008
            },
            {
              text: "Realizado Individual",
              service: "realizadoIndividual",
              permission: 1762
            },
            {
              text: "Realizado Equipe",
              service: "realizadoEquipe",
              permission: 1007
            }
          ]
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Relatórios Realizados");
  }
};
</script>

<style></style>
